import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import $ from 'jquery'; 
import ToolGif from './tool-gif.gif';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    state = { showing: true };

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    render() {
        const { showing } = this.state;
       
    return (
      <header>
            <header className="brk-header">
                <div className="brk-row">
                    <div className="brk-site-header">
                        <div className="brk-header-container">
                            <div className="brk-site-logo">
                                <Link to="/" >
                                    {/*<h1 className="brk-site-title brk-font-heading">*/}
                                    {/*    Brikk </h1>*/}
                                    <img src="assetsfiles/logo.svg" alt="TopAITools Logo image" />
                                </Link>
                            </div>


                            {/*<Link >*/}
                            {/*    <i className="material-icon-menu"></i>*/}
                            {/*    <span></span>*/}
                            {/*</Link>*/}

                            <Link to="javascript:;" className="d-xl-none d-lg-none d-md-none d-sm-block d-block position-absolute oggle-mobile-nav" onClick={() => this.setState({ showing: !showing })}><i className="material-icon-menu"></i></Link>
                            {showing
                                ? <div>
                                    <div class="brk-mobile-nav" style={{ visibility:'visible' }}>
                                        <div class="brk--header">
                                            <div class="brk-site-logo">
                                                <a href="/">
                                                    <span class="brk-site-title brk-font-heading">
                                                        <img src="assetsfiles/logo.svg" alt="logo image" /> </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="brk--nav">
                                            <nav class="brk-nav-mobile">
                                                <ul id="menu-primary-1" class="menu">
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1689"><a href="/">Home</a></li>
                                                    <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2692"><a href="#">Categories</a>
                                                        <ul class="sub-menu" style={{ display: "block" }} >
                                                            <li id="menu-item-1678" class="md-action-dynamic-explore menu-item menu-item-type-custom menu-item-object-custom menu-item-1678"><Link to="enc/AI_Assistant" target="_blank" onClick={() => this.setState({ showing: !showing })} >{/*<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACbElEQVR4nO2Yy2sUQRCHOxE96skXCF5UMlUTURYUvYgPyMWL4gbR3IJilGi2ahIfRDZCDl79L3QFQZyqUQSDN4WgYryJ16CCYiTHwEpLJhFXxN3peazMDwqWrd2d79vu6R7amDJlyqSTer3X19qQLdM0Pabb4mttCJWbtkDprOm2oPBULGBfm24LlgIpBxr1dSh0C4XnV6ZKuyU8b0eqMnt+rck6KHS/Y/BWkUam8J7SUWfwulxh7XBmAiB83bUAKF3LZ4VxN42mchUA5bcQURWVRkDp6x8Av/jCF5Y/M1c4AQyveCt95Yu/9y183AdlKJzAHh3dGPe9kAdbRiiiatzf/YQ3FU4AlO9aCS8aQxR+1zrPac7+8xYelO4VTgC76SYGoZvu9wGezEwAlU+7FvBCHsxMwD67gPKsK3gQfmOfrUyW8ULeDkIzieGVn9nfMnmlT2iXfTbyouBYOwXCR7xH4ztzA/8vU3l6dYOvtUO+BidQeRiFJn6W8rB9z/Z26Oh6U6R4Ie8HoTuo9BqUl/5hzi+h8Cv7HXhc25cbOCifQuGXyVcheoERn8wOPAy2gNCDFHZi6deJbanC94dcAeHPzuE13g/ok6e0NxV4DOkgCi+kBY+rEt/8KDjgFL4vGtua6PSh/c3to7vp1DQ9qPQ8K3hcHYkZJ2eqv55xZi8RnElGX6/3ovD73ASUP1Qb1TUd82NEA3nBY1wRDXQuEPJk7gJCNzoWAKFLuQsojSRaPkFoMbd7QGjR7vwmSVDoOCp9z37q8IK9tnER/+HlzSDBORCeRqHbaRYIT9tr2eMXJ/BlypQpU8b8JT8A+gyvJa5HXzEAAAAASUVORK5CYII=" alt="icon" width="13" />*/} AI Assistance </Link></li>
                                                            <li id="menu-item-1679" class="md-action-dynamic-explore menu-item menu-item-type-custom menu-item-object-custom menu-item-1679"><Link to="enc/Business" target="_blank" onClick={() => this.setState({ showing: !showing })}>Business </Link></li>
                                                            <li id="menu-item-1680" class="md-action-dynamic-explore menu-item menu-item-type-custom menu-item-object-custom menu-item-1680"><Link to="enc/Image_Generation" onClick={() => this.setState({ showing: !showing })} target="_blank">Image Generation </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Development" onClick={() => this.setState({ showing: !showing })} target="_blank"> Development </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Marketing" onClick={() => this.setState({ showing: !showing })} target="_blank"> Marketing  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/AI_Detection" onClick={() => this.setState({ showing: !showing })} target="_blank"> AI Detection  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Social_Media" onClick={() => this.setState({ showing: !showing })} target="_blank"> Social Media  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Chatbots" onClick={() => this.setState({ showing: !showing })} target="_blank"> Chatbots  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/ChatGPT" onClick={() => this.setState({ showing: !showing })} target="_blank"> ChatGPT  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Branding" onClick={() => this.setState({ showing: !showing })} target="_blank"> Branding  </Link></li>

                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Design" onClick={() => this.setState({ showing: !showing })} target="_blank"> Design  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Gaming" onClick={() => this.setState({ showing: !showing })} target="_blank"> Gaming  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Education" onClick={() => this.setState({ showing: !showing })} target="_blank"> Education  </Link></li>
                                                            <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Data_Analysis" onClick={() => this.setState({ showing: !showing })} target="_blank"> Data Analysis  </Link></li>
                                                           
                                                        </ul>
                                                </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1689"><a href="Blog/">Blog</a></li>
                                                    <li>
                                                        <div class="brk-site-cta">
                                                            <a href="#" class="btn btn-success text-white" data-modal="signin">
                                                                <span>  <i className="material-icon-build"></i> &nbsp;Submit Tool</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>

                                            </nav>
                                        </div>
                                        <div class="brk--footer">
                                            <div class="brk-ml-auto">
                                                <a href="#" class="brk--close" data-action="toggle-mobile-nav" onClick={() => this.setState({ showing: !showing })}>
                                                    <i class="fas fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            
                            <div className="col-xl-9 d-xl-flex d-lg-flex d-md-flex d-sm-none d-none align-items-center justify-content-end">
                            <div className="brk-site-nav">
                                <nav className="brk-nav brk-nav-main">
                                    <ul id="menu-primary" className="menu">
                                        <li id="menu-item-2692" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2692">
                                            <Link to="/">Home</Link>
                                        </li>
                                        {/*<li id="menu-item-2692" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2692">*/}
                                        {/*    <Link to="#">Features</Link>*/}
                                        {/*</li>*/}
                                            <li id="menu-item-2692" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2692 menu-item-has-children">
                                                <Link to="#">Categories</Link>
                                                <ul class="sub-menu">
                                                    
                                                    <li id="menu-item-1679" class="md-action-dynamic-explore menu-item menu-item-type-custom menu-item-object-custom menu-item-1679"><Link to="enc/AI_Assistant" target="_blank">AI Assistance </Link></li>
                                                    <li id="menu-item-1678" class="md-action-dynamic-explore menu-item menu-item-type-custom menu-item-object-custom menu-item-1678"><Link to="enc/Business" target="_blank">Business </Link></li>
                                                    <li id="menu-item-1680" class="md-action-dynamic-explore menu-item menu-item-type-custom menu-item-object-custom menu-item-1680"><Link to="enc/Image_Generation" target="_blank">Image Generation </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Development" target="_blank"> Development </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Marketing" target="_blank">Marketing</Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/AI_Detection" target="_blank">AI Detection</Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Social_Media" target="_blank"> Social Media  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Chatbots" target="_blank"> Chatbots  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/ChatGPT" target="_blank"> ChatGPT  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Branding" target="_blank"> Branding  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Design" target="_blank"> Design  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Gaming" target="_blank"> Gaming  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Education" target="_blank"> Education  </Link></li>
                                                    <li id="menu-item-2615" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2615"><Link to="enc/Data_Analysis" target="_blank"> Data Analysis  </Link></li>
                                                </ul>
                                        </li>
                                        <li id="menu-item-2692" className="menu-item menu-item-type-custom menu-item-object-custom  menu-item-2692">
                                            <Link to="Blog/">Blog</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="brk-site-actions">
                                <nav className="brk-nav">
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href="#" className="brk--pad" data-modal="signin">*/}
                                        {/*        <i className="material-icon-person"></i>*/}
                                        {/*        <span>Sign in</span>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <div className="">*/}
                                        {/*        <a href="#" className="submit-tool">*/}
                                        {/*            <span><i className="material-icon-build"></i> &nbsp;Submit tool</span>*/}
                                        {/*        </a>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}
                                        <li>
                                            <div class="brk-site-cta">
                                                <a href="#" class="btn btn-success text-white" data-modal="signin">
                                                        <span>  <i className="material-icon-build"></i> &nbsp;Submit Tool</span>
                                                </a>
                                            </div>
                                            </li>
                                    </ul>
                                </nav>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </header>

            
            <span className="rz-overlay"></span>
            <div className="rz-modal rz-modal-signin rz-modal-ready" data-id="signin" data-signup="pass">
                <Link to="#" className="rz-close">
                    <i className="fas fa-times"></i>
                </Link>
                <div className="rz-modal-heading">
                    <h4 className="rz--title">Submit your tool</h4>
                </div>
                <div className="rz-modal-content pb-5 pt-3">
                    <div className="rz-modal-append">
                        <div className="rz-modal-container">
                            <div className="load text-center" id="load" style={{ display: 'none' }}>
                                <img src={ToolGif} class="loading" alt="loading" /><br />
                                Sending your provided information. <br />Please wait...<br />
                            </div>
                            <form className="rz-form rz-signin-section rz-active" data-id="sign-in" autocomplete="nope">
                                <div className="rz-signin-social">
                                    <ul>
                                        <li>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label className="control-label pb-2"> Name</label>
                                                        <input type="text" value={this.state.Name} name="Name"
                                                            onChange={this.handleInputSubmitChange} placeholder="Enter Your Name" className="form-control" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label className="control-label pb-2">Email ID</label>
                                                        <input type="text" value={this.state.Email} name="Email"
                                                            onChange={this.handleInputSubmitChange} placeholder="Enter your Email Address" className="form-control" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label className="control-label pb-2">Mobile Number</label>
                                                        <input type="text" value={this.state.MobileNumber} name="MobileNumber"
                                                            onChange={this.handleInputSubmitChange} placeholder="Enter Your Mobile Number" className="form-control" required />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label className="control-label pb-2">URL</label>
                                                        <input type="text" value={this.state.Url} name="Url"
                                                            onChange={this.handleInputSubmitChange} placeholder="https://yoursitool.com/" className="form-control" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input type="button" onClick={this.handleButtonSubmit} value="Submit" className="btn btn-success btn-block w-100"></input>
                                            </div>
                                        </li>

                                    </ul>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
      </header>
    );
  }
}
