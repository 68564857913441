import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Zonlogo from './zon-logo.png';

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { toolsdata: [], loading: true, page: 1, };
        this.myRef = React.createRef();

    }
    componentDidMount() {
        this.populateWeatherData();
    }

    render() {

        return (
            <div>
                <div class="brk-footer">
                    <div class="brk--top">
                        <div class="brk-row">
                            <div class="brk--widgets">
                                <div class="brk-widget" data-id="text">
                                    <div class="textwidget">

                                        <form id="mc4wp-form-1" class="mc4wp-form mc4wp-form-2607" method="post" data-id="2607"
                                            data-name="Subscription">
                                            <div class="mc4wp-form-fields">
                                                <div class="brk-subscribe">
                                                    <span class="brk--name">Create your account, save tools &amp; stay updated
                                                    </span>
                                                    <div class="brk-buttons">
                                                        <div class="brk--button">
                                                            <a href="#" class="btn btn-success" data-modal="signin">
                                                                <span>CREATE MY ACCOUNT</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/*<div class="brk-input">*/}
                                                    {/*    <i class="fas fa-paper-plane"></i>*/}
                                                    {/*    <input type="email" name="EMAIL" required="" placeholder="Enter your email ..." />*/}
                                                    {/*    <button type="submit"><i class="fas fa-arrow-right"></i></button>*/}
                                                    {/*</div>*/}
                                                    {/*<span class="brk-summary">Receive a weekly digest of our handpicked top*/}
                                                    {/*    tools.*/}
                                                    {/*</span>*/}
                                                    {/*<p><strong>Unsubscribe anytime*/}

                                                    {/*</strong></p>*/}
                                              
                                                </div>
                                            </div>

                                            <div class="mc4wp-response"></div>
                                        </form>
                                    </div>
                                </div>
                                <div class="brk-widget" data-id="text">
                                    <div class="textwidget">
                                        <div class="rz--social">
                                            <ul className="pe-4">
                                                <li class="rz--instagram">
                                                    <a target="_blank" href="https://www.instagram.com/topaitools.in/" rel="noopener">
                                                        <i class="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li class="rz--facebook">
                                                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100095298407040" rel="noopener">
                                                        <i class="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                {/*<li class="rz--twitter">*/}
                                                {/*    <a target="_blank" href="#" rel="noopener">*/}
                                                {/*        <i class="fab fa-twitter"></i>*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                                {/*<li class="rz--google">*/}
                                                {/*    <a target="_blank" href="#" rel="noopener">*/}
                                                {/*        <i class="fab fa-youtube"></i>*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                             
                                                <li class="rz--youtube">
                                                    <a target="_blank" href="https://www.youtube.com/@topaitoolsindia/about " rel="noopener">
                                                        <i class="fab fa-youtube"></i>
                                                    </a>
                                                </li>
                                             
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="brk--bottom">
                        <div class="brk-row">
                            <div class="brk--bottom-inner">
                                <div class="brk--cell-copy">
                                    <p> <Link to="/policy" className="px-2 top" ref={this.myRef}>Privacy Policy</Link>|
                                        <Link to="/terms" className="px-2 top" ref={this.myRef}>Terms & Conditions</Link>
                                        </p>
                                </div>
                                <div class="brk--cell-copy">
                                    <p>&copy; Copyright 2023 <strong>TopAITools</strong>. All Rights Reserved.</p>
                                </div>
                                <div class="brk--cell-copy">
                                    <p> Made with 🦾 by <strong><Link to="https://zoninnovative.com/" target="_blank">{/*<img src={Zonlogo} alt="zon logo" /> */}Zon Innovative</Link></strong></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
          
        );
    }

    async populateWeatherData() {
        const response = await fetch('weatherforecast');
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }
}



export default Footer;