import React, { Component } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Listing.css';
import { DataFetch } from './DataFetch';




export class DetailsData extends Component {
    static displayName = DetailsData.name;
    constructor(props) {
        super(props);
        this.state = { categprydata: [], limit: 3, loading: true };
    }


    componentDidMount() {
        this.populateToolData();
    }

    static rendertooldetailsTable(tooldetails) {
        return (
            <div className='brk-main'>
                {tooldetails.map(tool =>

                    <>
                        <p>&nbsp;</p>
                        <div className="brk-row mt-5 pt-5">
                            <div className="rz-grid">
                                <div className="rz-col">
                                    <div className="rz-single">
                                        <div className="rz-container mt-lg-4">
                                            <div className="rz-content">
                                                <div className="">
                                                    <div className="brk-row">
                                                        <div className="brk-cover brk--gallery-lighbox bg-white">
                                                            <h2 className="rz-title px-3 py-3 ">{tool.toolName}</h2>
                                                            <div className="brk--images" data-size="1">
                                                                <img src={tool.image} alt="banner image" width="100%" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rz-single-heading pt-4">
                                                    <div className="rz-single-heading-title  justify-content-space-between">
                                                        <h1 className="rz-title">What is {tool.toolName} Tool?</h1>
                                                        <Link to={tool.link} className="float-right badge bg-dark text-green text-green-hover py-2" target="_blank">Visit Website <i className="material-icon-public vertical-bottom"></i></Link>
                                                    </div>
                                                </div>
                                                <div className="rz-single-content">
                                                    <div className="rz-mod-listing rz-mod-listing-content" data-type="content">
                                                        <div className="rz-mod-content">
                                                            <p dangerouslySetInnerHTML={{ __html: tool.long_Description }}>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="rz-mod-listing rz-mod-listing-gallery" data-type="gallery"></div>
                                                    {/*<div className="rz-mod-listing rz-mod-listing-content" data-type="content">*/}
                                                    {/*    <div className="rz-mod-content">*/}
                                                    {/*        <h4>AI possible use cases</h4>*/}
                                                    {/*        <p>Nunc accumsan, orci ac semper ultrices, erat sem hendrerit elit, at hendrerit neque orci a nisl. Nam mattis nisi arcu, vel ultrices augue consequat vel.</p>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="rz-mod-listing rz-mod-listing-meta" data-type="meta">*/}
                                                    {/*    <div className="rz-mod-content">*/}
                                                    {/*        <div className="rz--meta rz--style-list">*/}
                                                    {/*            <ul>*/}
                                                    {/*                <li>*/}
                                                    {/*                    <i className="material-icon-check text-success"></i>*/}
                                                    {/*                    <span>*/}
                                                    {/*                        Curabitur eget tortor iaculis*/}
                                                    {/*                    </span>*/}
                                                    {/*                </li>*/}
                                                    {/*                <li>*/}
                                                    {/*                    <i className="material-icon-check text-success"></i>*/}
                                                    {/*                    <span>*/}
                                                    {/*                        Curabitur eget tortor iaculis*/}
                                                    {/*                    </span>*/}
                                                    {/*                </li>*/}
                                                    {/*                <li>*/}
                                                    {/*                    <i className="material-icon-check text-success"></i>*/}
                                                    {/*                    <span>*/}
                                                    {/*                        Curabitur eget tortor iaculis*/}
                                                    {/*                    </span>*/}
                                                    {/*                </li>*/}
                                                    {/*            </ul>*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className="rz-mod-listing rz-mod-listing-author" data-type="author">
                                                        <div className="rz-mod-content">
                                                            <h4>AI Details</h4>
                                                            <div className="rz-author bg-white">
                                                                <div className="rz--heading">
                                                                    <div className="rz--avatar">
                                                                        <Link to="#">
                                                                            <div className="rz-avatar-placeholder"><i className="fas fa-money-check"></i></div>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="rz--meta">
                                                                        <div className="rz--name">
                                                                            <Link to="#">
                                                                                Pricing&nbsp; <span className="border bg-success btn-radius-10 text-white px-2 pe-2 small">{tool.subscription_Type}</span>
                                                                            </Link>
                                                                            <div className="rz--reviews pt-2 pt-lg-0">
                                                                                <i className="fas fa-tag"></i>
                                                                                <small>Check Price List</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="rz--description">
                                                                    <p>For more details please visit the ai website from below link</p>
                                                                </div>
                                                                <div className="rz--action">
                                                                    <a href={tool.link} target="_blank" className="rz-button rz-button-accent">
                                                                        <span>Visit {tool.toolName} Tools</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="rz-mod-listing rz-mod-listing-content" data-type="content">
                                                        <div className="rz-mod-content">
                                                            <h5 className="pb-4">Related Searches</h5>
                                                            <div className="elementor-container elementor-column-gap-no">
                                                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-53932d1"
                                                                    data-id="53932d1" data-element_type="column">
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div className="elementor-element elementor-element-b11b94a elementor-widget elementor-widget-brk-articles"
                                                                            data-id="b11b94a" data-element_type="widget" data-widget_type="brk-articles.default">
                                                                            <div className="elementor-widget-container">
                                                                                <DataFetch ref={this.componentARef} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="rz-sidebar">
                                                <div className="rz-single-sidebar rz--sticky">
                                                    <div className="rz--heading">
                                                        <div className="rz--col-heading">
                                                            <h4 className="rz--title rz-ellipsis"></h4>
                                                        </div>
                                                        <div className="rz--col-close">
                                                            <Link to="#" className="rz-close" data-action="toggle-mobile-action">
                                                                <i className="fas fa-times"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="rz-listing-action">
                                                        <h4>Featured AI Tools</h4>
                                                        <div className="rz-mod-action rz-mod-action-booking rz-ready p-3" data-type="booking">
                                                            <div className="rz-action-price mb-0">
                                                                <h5>GetBotz</h5>
                                                                <img src="/assets/img/tools/getbotz.webp" alt="image" />
                                                            </div>
                                                            <div className="rz-form">
                                                                <div className="rz-grid px-3 pt-2">
                                                                    <p>GetBotz helps you automate your blog using AI + SEO Data. It can publish SEO-optimized articles on the blog everyday using direct integrations like WordPress, Ghost, Shopify, Webflow and more. GetBotz can generate articles in 25+ languages.</p>
                                                                </div>
                                                                <div className="rz-action-success"></div>
                                                                <div className="rz-action-pricing"></div>

                                                            </div>
                                                        </div>

                                                        <div className="rz-action-footer rz-text-center">
                                                            <Link to="/" className="rz-button rz-button-accent rz-large" data-modal="signin">
                                                                <span>Checkout all Top AI Tools</span>
                                                                <div className="rz-preloader">
                                                                    <i className="fas fa-sync"></i>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></>
                )}
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : DetailsData.rendertooldetailsTable(this.state.tooldetails);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateToolData() {
        const id = window.location.href.split('/')[4]

        const response = await fetch('/api/Tools/Gettool/' + id.replace('~','.'));
        const data = await response.json();
        this.setState({ tooldetails: data.result, loading: false });
    }
}
export default DetailsData;
