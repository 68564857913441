import React, { Component, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import BlogaImage1 from './images/synthesia-banner.png';
import BlogaImage2 from './images/future-of-AI.png';
import BlogaImage3 from './images/freeguide.jpg';

export class Blog extends Component {

    constructor(props) {
        super(props);
        this.state = { toolsdata: [], loading: true, page: 1, };
        this.myRef = React.createRef()

    }
    handleButtonClickNew = () => {
        // Update the state when the button is clicked
        this.setState((prevState) => ({
            page: prevState.page + 1,
        }), () => {
            this.fetchData();
        });
    };
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.myRef.current.scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        this.myRef.current.scrollTo(0, 0);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>...</em></p>
            : this.rendertoolstable(this.state.toolsdata);
        return (
            <div>
                <header className="brk-page-title pt-5 mt-5">
                    <div className="brk-row">
                        <h1 className="brk--title text-center">Blog </h1>
                        <div className="brk-breadcrumb">
                            <ul><li><Link to="/">Home</Link></li><li><span>Blog</span></li></ul></div>
                    </div>
                </header>
                <div className="brk-row">
                    <main className="brk-main">
                        <div className="brk-content">
                            <div className="brk-msnry row" >
                                <div className="col-xl-4 brk--cell">
                                    <article className="brk--item">
                                        <Link to="Top-5-Alternatives-of-Synthesia" className="brk--image">
                                            <img width="640" height="426" src={BlogaImage1} className="attachment-large size-large wp-post-image" alt="image" /> </Link>
                                        <div className="brk--content">
                                            <h6>
                                                <Link className="brk--name" to="Top-5-Alternatives-of-Synthesia">
                                                    Top 5 Alternatives of Synthesia</Link>
                                            </h6>
                                            <div className="brk--meta">
                                                <div className="brk--meta-cell">
                                                    <Link to="Top-5-Alternatives-of-Synthesia" className="brk--category brk-bg">
                                                        <span>TOP AI TOOLS</span>
                                                    </Link>
                                                </div>
                                                <div className="brk--meta-cell">
                                                    <div className="brk--date">
                                                        17<sup>th</sup> Aug </div>
                                                </div>
                                            </div>
                                            <div className="brk--excerpt">
                                                <p>Synthesia is an efficient AI video creation tool that can generate videos for you within 15 minutes. Using Synthesia doesn't require you to be a pro video editor; instead, you can easily do it without any extra equipment.</p>
                                            </div>
                                            <p className="brk--more">
                                                <Link to="Top-5-Alternatives-of-Synthesia" className="text-success top" ref={this.myRef}>
                                                    Continue reading <i className="fa fa-angle-double-right" style={{ verticalAlign: 'middle' }}></i> </Link>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-xl-4 brk--cell">
                                    <article className="brk--item">
                                        <Link to="The-future-of-AI" className="brk--image">
                                            <img width="640" height="426" src={BlogaImage2} className="attachment-large size-large wp-post-image" alt="image" /> </Link>
                                        <div className="brk--content">
                                            <h6>
                                                <Link className="brk--name" to="The-future-of-AI">
                                                    The Future of AI: A Glimpse into the Next 5 Years</Link>
                                            </h6>
                                            <div className="brk--meta">
                                                <div className="brk--meta-cell">
                                                    <Link to="The-future-of-AI" className="brk--category brk-bg">
                                                        <span>TOP AI TOOLS</span>
                                                    </Link>
                                                </div>
                                                <div className="brk--meta-cell">
                                                    <div className="brk--date">
                                                        15<sup>th</sup> Aug </div>
                                                </div>
                                            </div>
                                            <div className="brk--excerpt">
                                                <p>Artificial Intelligence (AI) has been rapidly evolving, reshaping industries and the way we interact with technology. As we stand on the cusp of a new era...</p>
                                            </div>
                                            <p className="brk--more">
                                                <Link to="The-future-of-AI" className="text-success top" ref={this.myRef}>
                                                    Continue reading <i className="fa fa-angle-double-right" style={{ verticalAlign: 'middle' }}></i> </Link>
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-xl-4 brk--cell">
                                    <article className="brk--item">
                                        <Link to="Chat-GPT-Prompts-Free-Guide" className="brk--image">
                                            <img width="640" height="426" src={BlogaImage3} className="attachment-large size-large wp-post-image" alt="image" /> </Link>
                                        <div className="brk--content">
                                            <h6>
                                                <Link className="brk--name" to="Chat-GPT-Prompts-Free-Guide">
                                                    Chat GPT Prompts Free Guide</Link>
                                            </h6>
                                            <div className="brk--meta">
                                                <div className="brk--meta-cell">
                                                    <Link to="Chat-GPT-Prompts-Free-Guide" className="brk--category brk-bg">
                                                        <span>TOP AI TOOLS</span>
                                                    </Link>
                                                </div>
                                                <div className="brk--meta-cell">
                                                    <div className="brk--date">
                                                        13<sup>th</sup> Aug </div>
                                                </div>
                                            </div>
                                            <div className="brk--excerpt">
                                                <p>Explain the concept of blockchain technology to someone who has no technical background. Compose a dialogue between two characters who discover a hidden magical realm...</p>
                                            </div>
                                            <p className="brk--more">
                                                <Link to="Chat-GPT-Prompts-Free-Guide" className="text-success top" ref={this.myRef}>
                                                    Continue reading <i className="fa fa-angle-double-right" style={{ verticalAlign: 'middle' }}></i> </Link>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}
export default Blog;

