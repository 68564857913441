import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataFetch } from './DataFetch';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ToolGif from './tool-gif.gif';
import $ from 'jquery';



export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.componentARef = React.createRef();
        this.handleButtonSubmit = this.handleButtonSubmit.bind(this);
        this.state = { categprydata: [], options: [], inputValue: '', Name: '', MobileNumber: '', Email: '', Url: '', loading: true };

    }

    componentDidMount() {
        this.populateCategoryData();
    }
    scrollToCenter = () => {
        if (this.centerElement) {
            this.centerElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center',
            });
        }
    };
    handleButtonSubmit() {
        var x = document.getElementById("load");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
        this.state.loading = true;
        try {
            fetch('/api/category/PostTool', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Name: this.state.Name,
                    Url: this.state.Url,
                    Email: this.state.Email,
                    MobileNumber: this.state.MobileNumber,
                }),
            }).then((response) => {
                alert('Provided information successfully sent. One of our team member will call you soon. Thank you for beign with us.');
                /*   history.push("/");*/
                if (x.style.display === "block") {
                    x.style.display = "none";
                    this.state.Name = '';
                    this.state.Url = '';
                    this.state.Email = '';
                    this.state.MobileNumber = '';
                    window.location.reload(false);

                }
                return response.json();

            })
        }
        catch (err) {
            console.log(err);
        }
    }
    handleButtonClick(catId) {
        if (catId == "0") {
                // Do something with the catId when the button is clicked
            this.componentARef.current.handleButtonClick(catId, this.state.inputValue);
            this.setState({ isClicked: true });
        }
        else {
            this.componentARef.current.handleButtonClick(catId, '0');

        }
        this.scrollToCenter();
        // Add any other logic you want to perform when the button is clicked
    }

    keyPress = (event) => {
        if (event.key == 'Enter') {
            // How would I trigger the button that is in the render? I have this so far.
            //this.handleButtonClick();
            this.handleButtonClick('0')
        }
    }


    handleInputSubmitChange = (event) => {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        // Update the inputValue in the state whenever the user types
        this.setState({
            [name]: value,
        });
        //this.populateAutoData(event.target.value);
    };

    handleInputChange = (event) => {
        // Update the inputValue in the state whenever the user types
        this.setState({
            inputValue: event.target.value,
        });
        //this.populateAutoData(event.target.value);
    };

    rendercategories(categprydata) {
        return (
            <ul className="brk-carousel-nav">
                {categprydata.map(tool =>
                    <li key={tool.cat_Id} data-for="0" className="brk-active">
                        <a href={`/enc/${tool.category_Name.replaceAll(' ', '_').replaceAll('.', '_')}`} target="_blank" className="brk-bg">
                            <span>{tool.category_Name}</span>
                        </a>
                    </li>
                )}
            </ul>
        )
    }
    async populateAutoData(inputvalue) {
        const response = await fetch(`/api/Tools/Getautcomplete/?q=${inputvalue}`);
        const data = await response.json();
        const options = data;
        this.setState({ options });
    }
    async populateCategoryData() {
        const response = await fetch('/api/Category/GetAllCategory');
        const data = await response.json();
        this.setState({ categprydata: data.result, loading: false });
    }
    render() {
        const options = this.state.options;
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.rendercategories(this.state.categprydata);
        return (

            <div className="">
                <div className="brk-container">
                    <main className="brk-main">
                        <div className="brk-content">
                            <article className="post-1625 page type-page status-publish hentry">
                                <div className="brk-page-content">
                                    <div data-elementor-type="wp-page" data-elementor-id="1625" className="elementor elementor-1625"
                                        data-elementor-settings="[]">
                                        <div className="elementor-section-wrap">

                                            <section
                                                className="elementor-section elementor-top-section elementor-element elementor-element-79567c3 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle">
                                                <div className="elementor-background-overlay"></div>
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-12ae769"
                                                        data-id="12ae769" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-2a62300 elementor-widget elementor-widget-brk-carousel"
                                                                data-id="2a62300" data-element_type="widget"
                                                                data-widget_type="brk-carousel.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="brk--cont-heading brk-active">
                                                                        <header className="brk--main">
                                                                            <h1 className="brk--title">
                                                                                Top 3500+ AI Tools for<br /> Diverse Applications Curated Directory
                                                                            </h1>
                                                                            <h5 className="brk--bottom-text text-green">
                                                                                Check out the TopAITools, which relate to 3500+ AI Tools in 100+ Categories.
                                                                            </h5>
                                                                        </header>
                                                                    </div>
                                                                    <section className="brk-section brk-elementor-row">
                                                                        <div
                                                                            className="brk-carousel-search brk-flex--full brk-justify-center brk--style-horizontal brk--position-bottom">
                                                                            <div className="brk--container">


                                                                                <div className="brk--content brk--cont-search brk-active"
                                                                                    data-id="0">
                                                                                    <div className="brk-carousel-search-type">
                                                                                        <div className="rz-search-form" data-form-id="1611">
                                                                                            <form
                                                                                                action="#"
                                                                                                method="get" autocomplete="nope">
                                                                                                <input type="hidden" name="type"
                                                                                                    value="homes" />
                                                                                                <div className="rz--form">
                                                                                                    <div className="rz-search-mods">
                                                                                                        <div className="rz-mod"
                                                                                                            data-type="autocomplete"
                                                                                                            data-col="12">
                                                                                                            <div className="rz-form-group rz-field rz-col-12"
                                                                                                                data-search-form="1611"
                                                                                                                data-taxonomy="rz_listing_region"
                                                                                                                data-icon="fas fa-map-marker-alt"
                                                                                                                data-type="autocomplete"
                                                                                                                data-storage="request"
                                                                                                                data-disabled="no"
                                                                                                                data-heading="Search"
                                                                                                                data-id="listing_region">
                                                                                                                <div className="rz-heading">
                                                                                                                    <label class>
                                                                                                                        Search
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="rz-quick">
                                                                                                                    <div
                                                                                                                        className="rz-quick-input">
                                                                                                                        {/*<i className="rz-quick-preloader fas fa-sync fa-spin"></i>*/}
                                                                                                                        <input
                                                                                                                            className="rz-quick-label rz-transition"
                                                                                                                            type="text" id="text-search"
                                                                                                                            placeholder="Search here for AI tool..."
                                                                                                                            value={this.state.inputValue}
                                                                                                                            onChange={this.handleInputChange} tabIndex="1" onKeyPress={this.keyPress} />
                                                                                                                        <ul className="drop-complete">
                                                                                                                            {options.map((option) => (
                                                                                                                                <a href="javascript:;">
                                                                                                                                    <li key={option} onClick={() => this.setState({ inputValue: option })}>
                                                                                                                                        {option}
                                                                                                                                    </li>
                                                                                                                                </a>
                                                                                                                            ))}
                                                                                                                        </ul>

                                                                                                                        <a href="#"
                                                                                                                            className="rz-icon-clear fas fa-times" ></a>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="rz-autocomplete rz-is-empty">
                                                                                                                        <ul
                                                                                                                            className="rz-listed">

                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className="rz-search-submit" tabIndex="2">
                                                                                                        <button id="srch-btn" type="button" onClick={() => { this.handleButtonClick('0') }} 
                                                                                                            className="rz-button rz-button-accent">
                                                                                                            <span className="rz--icon"><i
                                                                                                                className="fas fa-search"></i></span>
                                                                                                            {/*<div className="rz-preloader">*/}
                                                                                                            {/*    <i*/}
                                                                                                            {/*        className="fas fa-sync"></i>*/}
                                                                                                            {/*</div>*/}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="space"></div>
                                                                                {contents}

                                                                                <div className="brk--content brk--cont-search" data-id="1">
                                                                                    <div className="brk-carousel-search-type">
                                                                                        <div className="rz-search-form" data-form-id="1627">
                                                                                            <form
                                                                                                action="#"
                                                                                                method="get" autocomplete="nope" >
                                                                                                <input type="hidden" name="type"
                                                                                                    value="restaurants" />
                                                                                                <div className="rz--form">
                                                                                                    <div className="rz-search-mods">
                                                                                                        <div className="rz-mod"
                                                                                                            data-type="autocomplete"
                                                                                                            data-col="12">
                                                                                                            <div className="rz-form-group rz-field rz-col-12"
                                                                                                                data-search-form="1627"
                                                                                                                data-taxonomy="food-type"
                                                                                                                data-icon="fas fa-pizza-slice"
                                                                                                                data-type="autocomplete"
                                                                                                                data-storage="request"
                                                                                                                data-disabled="no"
                                                                                                                data-heading="Search"
                                                                                                                data-id="food-type">
                                                                                                                <div className="rz-heading">
                                                                                                                    <label class>
                                                                                                                        Search
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="rz-quick">
                                                                                                                    <div
                                                                                                                        className="rz-quick-input">
                                                                                                                        <i
                                                                                                                            className="rz-quick-preloader fas fa-sync fa-spin"></i>
                                                                                                                        <input
                                                                                                                            className="rz-quick-label rz-transition"
                                                                                                                            name
                                                                                                                            type="text"
                                                                                                                            placeholder="Start typing, try `pizza` ..."
                                                                                                                            value
                                                                                                                            autocomplete="nope" />
                                                                                                                        <input
                                                                                                                            className="rz-quick-value"
                                                                                                                            type="hidden"
                                                                                                                            name="food-type"
                                                                                                                            value />
                                                                                                                        <a href="#"
                                                                                                                            className="rz-icon-clear fas fa-times"></a>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="rz-autocomplete rz-is-empty">
                                                                                                                        <ul
                                                                                                                            className="rz-listed">

                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="rz-mod"
                                                                                                            data-type="tab"
                                                                                                            data-col="12">
                                                                                                            <div
                                                                                                                className="rz-form-group rz-col-12">
                                                                                                                <div className="rz-heading">
                                                                                                                    <label
                                                                                                                        className="rz-ellipsis">
                                                                                                                        Types of
                                                                                                                        cuisine
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="rz-filter-tab ">
                                                                                                                    <div className="rz-tab-title rz-is-placeholder rz-no-select"
                                                                                                                        data-label="Types of cuisine">
                                                                                                                        <span>Types
                                                                                                                            of
                                                                                                                            cuisine</span>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="rz-tab-flyout">
                                                                                                                        <div
                                                                                                                            className="rz-tab-content rz-scrollbar">
                                                                                                                            <div
                                                                                                                                className="rz-grid">
                                                                                                                                <div className="rz-mod"
                                                                                                                                    data-type="taxonomy"
                                                                                                                                    data-col="12">
                                                                                                                                    <div className="rz-form-group rz-field rz-col-12"
                                                                                                                                        data-type="radio"
                                                                                                                                        data-storage="request"
                                                                                                                                        data-disabled="no"
                                                                                                                                        data-heading="Types of cuisine"
                                                                                                                                        data-id="food-type">
                                                                                                                                        <div
                                                                                                                                            className="rz-heading">
                                                                                                                                            <label
                                                                                                                                                class>
                                                                                                                                                Types
                                                                                                                                                of
                                                                                                                                                cuisine
                                                                                                                                            </label>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            className="rz-radio-fieldset">
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="burgers" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Burgers</span>
                                                                                                                                            </label>
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="chinese" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Chinese</span>
                                                                                                                                            </label>
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="fast-food" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Fast
                                                                                                                                                    food</span>
                                                                                                                                            </label>
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="gourme" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Gourme</span>
                                                                                                                                            </label>
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="italian" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Italian</span>
                                                                                                                                            </label>
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="pizza" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Pizza</span>
                                                                                                                                            </label>
                                                                                                                                            <label
                                                                                                                                                className="rz-radio">
                                                                                                                                                <input
                                                                                                                                                    type="radio"
                                                                                                                                                    name="food-type"
                                                                                                                                                    value="traditional" />
                                                                                                                                                <i
                                                                                                                                                    className="rz-transition"></i>
                                                                                                                                                <span>Traditional</span>
                                                                                                                                            </label>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="rz-tab-footer">
                                                                                                                            <div>
                                                                                                                                <a href="#"
                                                                                                                                    className="rz-button rz-small">
                                                                                                                                    <span>Close</span>
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="rz-mod"
                                                                                                            data-type="tab"
                                                                                                            data-col="12">
                                                                                                            <div
                                                                                                                className="rz-form-group rz-col-12">
                                                                                                                <div className="rz-heading">
                                                                                                                    <label
                                                                                                                        className="rz-ellipsis">
                                                                                                                        Where
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="rz-filter-tab ">
                                                                                                                    <div className="rz-tab-title rz-is-placeholder rz-no-select"
                                                                                                                        data-label="Where">
                                                                                                                        <span>Where</span>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="rz-tab-flyout">
                                                                                                                        <div
                                                                                                                            className="rz-tab-content rz-scrollbar">
                                                                                                                            <div
                                                                                                                                className="rz-grid">
                                                                                                                                <div className="rz-mod"
                                                                                                                                    data-type="taxonomy"
                                                                                                                                    data-col="12">
                                                                                                                                    <div className="rz-form-group rz-field rz-col-12"
                                                                                                                                        data-type="select2"
                                                                                                                                        data-storage="request"
                                                                                                                                        data-disabled="no"
                                                                                                                                        data-heading="Region"
                                                                                                                                        data-id="listing_region">
                                                                                                                                        <div
                                                                                                                                            className="rz-heading">
                                                                                                                                            <label
                                                                                                                                                class>
                                                                                                                                                Region
                                                                                                                                            </label>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            className="rz-select2">
                                                                                                                                            <select
                                                                                                                                                name="rz_listing_region">
                                                                                                                                                <option
                                                                                                                                                    value>
                                                                                                                                                    -
                                                                                                                                                </option>
                                                                                                                                                <option
                                                                                                                                                    value="barcelona">
                                                                                                                                                    Barcelona
                                                                                                                                                </option>
                                                                                                                                                <option
                                                                                                                                                    value="gava">
                                                                                                                                                    Gava
                                                                                                                                                </option>
                                                                                                                                                <option
                                                                                                                                                    value="region-1">
                                                                                                                                                    Sant
                                                                                                                                                    Pere
                                                                                                                                                    de
                                                                                                                                                    Ribes
                                                                                                                                                </option>
                                                                                                                                                <option
                                                                                                                                                    value="sitges">
                                                                                                                                                    Sitges
                                                                                                                                                </option>
                                                                                                                                                <option
                                                                                                                                                    value="tarragona">
                                                                                                                                                    Tarragona
                                                                                                                                                </option>
                                                                                                                                                <option
                                                                                                                                                    value="vilanova-i-la-geltru">
                                                                                                                                                    Vilanova
                                                                                                                                                    i
                                                                                                                                                    la
                                                                                                                                                    Geltru
                                                                                                                                                </option>
                                                                                                                                            </select>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="rz-mod"
                                                                                                                                    data-type="checkbox"
                                                                                                                                    data-col="12">
                                                                                                                                    <div className="rz-form-group rz-field rz-col-12"
                                                                                                                                        data-type="checkbox"
                                                                                                                                        data-storage="request"
                                                                                                                                        data-disabled="no"
                                                                                                                                        data-heading="Home delivery?"
                                                                                                                                        data-id="delivery">
                                                                                                                                        <div
                                                                                                                                            className="rz-heading">
                                                                                                                                            <label
                                                                                                                                                className=" mb-0 ">
                                                                                                                                                Home
                                                                                                                                                delivery?
                                                                                                                                            </label>
                                                                                                                                            <p>Mark
                                                                                                                                                this
                                                                                                                                                option
                                                                                                                                                if
                                                                                                                                                you
                                                                                                                                                want
                                                                                                                                                to
                                                                                                                                                search
                                                                                                                                                for
                                                                                                                                                home
                                                                                                                                                delivery
                                                                                                                                                restaurants.
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                        <label
                                                                                                                                            className="rz-checkbox rz-no-select">
                                                                                                                                            <input
                                                                                                                                                type="checkbox"
                                                                                                                                                value="1" />
                                                                                                                                            <span
                                                                                                                                                className="rz-transition"></span>
                                                                                                                                            <em>Yes</em>
                                                                                                                                        </label>
                                                                                                                                        <input
                                                                                                                                            type="hidden"
                                                                                                                                            name="delivery"
                                                                                                                                            value />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="rz-tab-footer">
                                                                                                                            <div>
                                                                                                                                <a href="#"
                                                                                                                                    className="rz-button rz-small">
                                                                                                                                    <span>Close</span>
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="rz-search-submit">
                                                                                                        <button type="submit"
                                                                                                            className="rz-button rz-button-accent">
                                                                                                            <span className="rz--icon"><i
                                                                                                                className="fas fa-search"></i></span>
                                                                                                            <div className="rz-preloader">
                                                                                                                <i
                                                                                                                    className="fas fa-sync"></i>
                                                                                                            </div>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                className="elementor-section elementor-top-section elementor-element elementor-element-e3e19c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="e3e19c6" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-31958f5"
                                                        data-id="31958f5" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-9aa1049 elementor-widget elementor-widget-brk-heading"
                                                                data-id="9aa1049" data-element_type="widget" data-widget_type="brk-heading.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="brk-section brk-elementor-row">
                                                                        <div className="brk--heading" >
                                                                            <h2 className="brk--title brk--size-large text-center">
                                                                                <span>Categories</span> </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                className="elementor-section elementor-top-section elementor-element elementor-element-ea3fc2a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="ea3fc2a" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-18c16a5"
                                                        data-id="18c16a5" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-159b91c elementor-widget elementor-widget-brk-boxes-trendy"
                                                                data-id="159b91c" data-element_type="widget" data-widget_type="brk-boxes-trendy.default">
                                                                <div className="elementor-widget-container">
                                                                    <section className="brk-section brk-elementor-row">
                                                                        <div className="brk-flex--full brk-justify-center">
                                                                            <div className="brk-boxes-trendy-container">
                                                                                <div className="brk-boxes-trendy brk--rounded brk-no-select brk--x8">

                                                                                    <div className="brk--cell">


                                                                                        <a href="#" onClick={() => { this.handleButtonClick('AI Detection') }} className="brk--item">
                                                                                            <div className="brk--image categoryImg1">
                                                                                                <div className="brk--inner">
                                                                                                    <span className="brk--overlay"></span>

                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="brk--name brk-font-heading">
                                                                                                AI Detection </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="brk--cell">
                                                                                        <a href="#" onClick={() => { this.handleButtonClick('Development') }} className="brk--item">
                                                                                            <div className="brk--image categoryImg2">
                                                                                                <div className="brk--inner">
                                                                                                    <span className="brk--overlay"></span>

                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="brk--name brk-font-heading">
                                                                                                Development </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="brk--cell">
                                                                                        <a href="#" onClick={() => { this.handleButtonClick('Business') }} className="brk--item">
                                                                                            <div className="brk--image categoryImg3">
                                                                                                <div className="brk--inner">
                                                                                                    <span className="brk--overlay"></span>

                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="brk--name brk-font-heading">
                                                                                                Business </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="brk--cell">
                                                                                        <a href="#" onClick={() => { this.handleButtonClick('Data Analysis') }} className="brk--item">
                                                                                            <div className="brk--image categoryImg4">
                                                                                                <div className="brk--inner">
                                                                                                    <span className="brk--overlay"></span>

                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="brk--name brk-font-heading">
                                                                                                Data Analysis </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="brk--cell">
                                                                                        <a href="#" onClick={() => { this.handleButtonClick('Design') }} className="brk--item">
                                                                                            <div className="brk--image categoryImg5">
                                                                                                <div className="brk--inner">
                                                                                                    <span className="brk--overlay"></span>

                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="brk--name brk-font-heading">
                                                                                                Design </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="brk--cell" >
                                                                                        <a href="#" onClick={() => { this.handleButtonClick('Education') }} className="brk--item">
                                                                                            <div className="brk--image categoryImg6">
                                                                                                <div className="brk--inner">
                                                                                                    <span className="brk--overlay"></span>

                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="brk--name brk-font-heading">
                                                                                                Education </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div ></div>
                                            <section ref={(ref) => (this.centerElement = ref)}
                                                className="elementor-section elementor-top-section elementor-element elementor-element-b3f39b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="b3f39b7" data-element_type="section">

                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-53932d1"
                                                        data-id="53932d1" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-b11b94a elementor-widget elementor-widget-brk-articles"
                                                                data-id="b11b94a" data-element_type="widget" data-widget_type="brk-articles.default">
                                                                <div className="elementor-widget-container">


                                                                    <DataFetch ref={this.componentARef} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>






                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </main>
                </div>
                

            </div>

        );
    }
}
export default Home;

