import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForecastsTable(this.state.forecasts);

        return (
            <div>
                <div className="pb-3">
                    <div className="brk-row mt-4">
                        <div className="brk-cover brk--gallery-lighbox">
                            <h2 className="rz-title px-1">AI Tool</h2>
                            <div className="brk--images" data-size="1">
                                <img src="https://utillz.com/themes/brikk/booking/wp-content/uploads/sites/2/2020/10/photo-1612596551898-929726fd7a4e-1118x1006.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brk-row">
                    <div className="rz-grid">
                        <div className="rz-col">
                            <div className="rz-single">
                                <div className="rz-container">
                                    <div className="rz-content">
                                        <div className="rz-single-heading">
                                            <div className="rz-single-heading-title">
                                                <h1 className="rz-title">What is AI Tool?</h1>
                                            </div>
                                        </div>
                                        <div className="rz-single-content">
                                            <div className="rz-mod-listing rz-mod-listing-content" data-type="content">
                                                <div className="rz-mod-content">
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo dapibus tellus. Fusce in lacus scelerisque dui sodales varius. Etiam ornare pharetra justo, eu tristique nunc. Sed erat mi,
                                                        vehicula non est ut, bibendum malesuada turpis.
                                                    </p>
                                                    <p>Curabitur eget tortor iaculis, pellentesque sapien vel, congue dolor. In magna augue, porttitor vitae tortor vel, mattis nisi arcu rhoncus tristique nisi.</p>
                                                </div>
                                            </div>
                                            <div className="rz-mod-listing rz-mod-listing-gallery" data-type="gallery"></div>
                                            <div className="rz-mod-listing rz-mod-listing-content" data-type="content">
                                                <div className="rz-mod-content">
                                                    <h4>AI possible use cases</h4>
                                                    <p>Nunc accumsan, orci ac semper ultrices, erat sem hendrerit elit, at hendrerit neque orci a nisl. Nam mattis nisi arcu, vel ultrices augue consequat vel.</p>
                                                </div>
                                            </div>
                                            <div className="rz-mod-listing rz-mod-listing-meta" data-type="meta">
                                                <div className="rz-mod-content">
                                                    <div className="rz--meta rz--style-list">
                                                        <ul>
                                                            <li>
                                                                <i className="material-icon-check text-success"></i>
                                                                <span>
                                                                    Curabitur eget tortor iaculis
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <i className="material-icon-check text-success"></i>
                                                                <span>
                                                                    Curabitur eget tortor iaculis
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <i className="material-icon-check text-success"></i>
                                                                <span>
                                                                    Curabitur eget tortor iaculis
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rz-mod-listing rz-mod-listing-author" data-type="author">
                                                <div className="rz-mod-content">
                                                    <h4>AI Details</h4>
                                                    <div className="rz-author">
                                                        <div className="rz--heading">
                                                            <div className="rz--avatar">
                                                                <Link to="#">
                                                                    <div className="rz-avatar-placeholder"><i className="fas fa-money-check"></i></div>
                                                                </Link>
                                                            </div>
                                                            <div className="rz--meta">
                                                                <div className="rz--name">
                                                                    <Link>
                                                                        Pricing&nbsp; <span className="border bg-success text-white px-2 py-1 pe-2">FreePremium</span>
                                                                    </Link>
                                                                    <div className="rz--reviews">
                                                                        <i className="fas fa-tag"></i>
                                                                        <span>Check Price List</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="rz--description">
                                                            <p>Vestibulum dignissim est tortor, nec euismod lectus placerat nec.</p>
                                                        </div>
                                                        <div className="rz--action">
                                                            <Link to="#" className="rz-button rz-button-accent" data-modal="signin" data-params='{"id":10279}'>
                                                                <span>Contact Top AI Tools</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="rz-mod-listing rz-mod-listing-content" data-type="content">
                                                <div className="rz-mod-content">
                                                    <h5>Related Searches</h5>
                                                    <div className="routiz">
                                                        <div className="rz-nearby">
                                                            <div className="rz-distance-outer">
                                                                <span className="rz-distance"> &nbsp; AI Tool 1&nbsp; </span>&nbsp;&nbsp;
                                                                <span className="rz-distance"> &nbsp; AI Tool 2&nbsp; </span>&nbsp;&nbsp;
                                                                <span className="rz-distance">&nbsp; AI Tool 3&nbsp; </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="rz-sidebar">
                                        <div className="rz-single-sidebar rz--sticky">
                                            <div className="rz--heading">
                                                <div className="rz--col-heading">
                                                    <h4 className="rz--title rz-ellipsis"></h4>
                                                </div>
                                                <div className="rz--col-close">
                                                    <Link to="#" className="rz-close" data-action="toggle-mobile-action">
                                                        <i className="fas fa-times"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="rz-listing-action">
                                                <h4 className="rz--price rz-font-heading">Featured AI Tools</h4>
                                                {/*box*/}
                                                <div className="rz-mod-action rz-mod-action-booking rz-ready p-3" data-type="booking">
                                                    <div className="rz-action-price mb-0">
                                                        <h5>Agent4 AI</h5>
                                                        <img src="https://topai.tools/assets/img/tools/getbotz.webp" alt="image" />
                                                    </div>
                                                    <div className="rz-form">
                                                        <div className="rz-grid px-3 pt-2">
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo dapibus tellus. Fusce in lacus scelerisque dui sodales varius. </p>
                                                        </div>
                                                        <div className="rz-action-success"></div>
                                                        <div className="rz-action-pricing"></div>

                                                    </div>
                                                </div>
                                                {/*box ends*/}
                                                {/*box*/}
                                                <div className="rz-mod-action rz-mod-action-booking rz-ready p-3" data-type="booking">
                                                    <div className="rz-action-price mb-0">
                                                        <h5>Agent4 AI</h5>
                                                        <img src="https://topai.tools/assets/img/tools/getbotz.webp" alt="image" />
                                                    </div>
                                                    <div className="rz-form">
                                                        <div className="rz-grid px-3 pt-2">
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo dapibus tellus. Fusce in lacus scelerisque dui sodales varius. </p>
                                                        </div>
                                                        <div className="rz-action-success"></div>
                                                        <div className="rz-action-pricing"></div>

                                                    </div>
                                                </div>
                                                {/*box ends*/}
                                                <div className="rz-action-footer rz-text-center">
                                                    <Link to="#" className="rz-button rz-button-accent rz-large" data-modal="signin">
                                                        <span>Checkout all AI Tools</span>
                                                        <div className="rz-preloader">
                                                            <i className="fas fa-sync"></i>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {contents}
            </div>
        );
    }


  async populateWeatherData() {
    const response = await fetch('weatherforecast');
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
