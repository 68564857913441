import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { DataFetch } from "./components/DataFetch";
import { DetailsData } from "./components/DetailsData";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsConditions } from "./components/TermsConditions";
import { Blog } from "./components/Blog/Blog";
import { Top_5_Alternatives_of_Synthesia } from "./components/Blog/Top-5-Alternatives-of-Synthesia";
import { The_future_of_AI } from "./components/Blog/The-future-of-AI";
/*import { Chat_GPT_Prompts_Free_Guide } from "./components/Blog/Chat-GPT-Prompts-Free-Guide";*/
import { Enc } from "./components/enc";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
    },
  {
      path: '/terms',
      element: <TermsConditions />
    },
    {
        path: '/policy',
        element: <PrivacyPolicy />
    },
    {
        path: '/datafetch',
        element: <DataFetch />
    }
    ,
    {
        path: '/detailsdata/:id',
        element: <DetailsData />
    }
    ,
    {
        path: '/enc/:catname',
        element: <Enc />
    },
     {
        path: '/Blog/',
        element: <Blog />
    },
       {
           path: '/Blog/Top-5-Alternatives-of-Synthesia',
           element: <Top_5_Alternatives_of_Synthesia />
    },
    {
        path: '/Blog/The-future-of-AI',
        element: <The_future_of_AI />
    },
    //{
    //    path: '/Blog/Chat-GPT-Prompts-Free-Guide',
    //    element: <Chat_GPT_Prompts_Free_Guide />
    //}
];

export default AppRoutes;

