import React, { Component ,useEffect } from 'react';
import Home from './Home';
import { Link } from 'react-router-dom';
import WindowLink from './window-link.png';


export class DataFetch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toolsdata: [], loading: true, page: 1, maxLength: 100 };
        this.handleButtonClick = this.handleButtonClick.bind(this);

    }
    getShortenedHTML(html) {
    const { maxLength } = this.state;

    // Use a temporary div element to parse and manipulate the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Get the text content and substring it
    let shortenedText = tempDiv.textContent.substring(0, maxLength);

    // Ensure that the shortened text doesn't cut off in the middle of an HTML tag
    const lastIndexOfTag = shortenedText.lastIndexOf('<');
    const lastIndexOfClosingTag = shortenedText.lastIndexOf('>');
    if (lastIndexOfTag > lastIndexOfClosingTag) {
      shortenedText = shortenedText.slice(0, lastIndexOfTag);
    }

    // Append ellipsis if the text was shortened
    if (html.length > maxLength) {
      shortenedText += '...';
    }

    return shortenedText;
  }


    handleButtonClickNew = () => {
        // Update the state when the button is clicked
        this.setState((prevState) => ({
            page: prevState.page + 1,
        }), () => {
            this.fetchData();
        });
    };
    //handleScroll = () => {
    //    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    //    const { isLoading } = this.state;

    //    if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
    //        this.setState((prevState) => ({
    //            page: prevState.page + 1,
    //        }), () => {
    //            this.fetchData();
    //        });
    //    }
    //};
    fetchData = async () => {
     
        try {
            const { page, toolsdata } = this.state;
            this.setState({ isLoading: true });

            const response = await fetch(`/api/Tools/Gettoolslazy?page=${page}`);
            const newData = await response.json();
            this.setState({
                toolsdata: [...toolsdata, ...newData.result], // Append new data to existing data
                isLoading: false,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ isLoading: false });
        }
    };
    componentDidMount() {
        this.populateWeatherData();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    //handleSearchButtonClick() {
    //    try {
    //        fetch('/api/Tools/Getsearchtools?searchkey=', {
    //            method: "get",
    //            // data:  person,
    //        }).then((response) => {
    //            return response.json();
    //        })
    //            .then((jsonData) => {
    //                this.setState({ toolsdata: jsonData.result, loading: false });
    //            });
    //    } catch (err) {
    //        console.log(err);
    //    }
    //}
    handleButtonClick(catId, searchkey) {

        // Do something with the catId when the button is clicked
        //console.log('Button clicked for category ID:', catId);
        try {
            if (searchkey == '0') {
                fetch('/api/Tools/Gettools?catid=' + catId, {
                    method: "get",
                    // data:  person,
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        this.setState({ toolsdata: jsonData.result, loading: false });
                    });
            }
            else {
                fetch('/api/Tools/Getsearchtools?searchkey=' + searchkey, {
                    method: "get",
                    // data:  person,
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        this.setState({ toolsdata: jsonData.result, loading: false });
                    });
            }
        } catch (err) {
            console.log(err);
        }
        // Add any other logic you want to perform when the button is clicked
    }
     rendertoolstable(toolsdata) {
        return (

            <div className='' aria-labelledby="tabelLabel">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-4ea2ed1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="4ea2ed1" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1db6768"
                            data-id="1db6768" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-5850cc1 elementor-widget elementor-widget-brk-heading"
                                    data-id="5850cc1" data-element_type="widget" data-widget_type="brk-heading.default">
                                    <div class="elementor-widget-container">
                                        <div class="brk-section brk-elementor-row">
                                            <div class="brk--heading">
                                                <h2 class="brk--title brk--size-large text-center">
                                                    <span>Explore Top AI Tools</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-b3f39b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="b3f39b7" data-element_type="section">

                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-53932d1"
                            data-id="53932d1" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-b11b94a elementor-widget elementor-widget-brk-articles"
                                    data-id="b11b94a" data-element_type="widget" data-widget_type="brk-articles.default">
                                    <div class="elementor-widget-container">
                                        <div class="brk-listing-summary mb-3">
                                            <div class="brk--viewing">
                                                <p>
                                                    Robo found <strong className="text-success h4">{toolsdata.length}</strong> tools for you
                                                </p>
                                            </div>
                                        </div>
                                        <section class="brk-section brk-elementor-row">
                                            <div class="brk-flex--full brk-justify-center">
                                                <div class="brk-articles" data-cols="3">
                                                    <div class="brk-grid brk-justify-center">
                                                        {toolsdata.map((tool, index) =>
                                                            <>

                                                                <div class="brk--cell" id={tool.tool_Id}>
                                                                    <a target="_blank" href={`/detailsdata/${tool.toolName.replaceAll(' ', '_').replaceAll('.', '~') }`}>
                                                                        <article class="brk--item">

                                                                            <a target="_blank" href={`/detailsdata/${tool.toolName.replaceAll(' ', '_').replaceAll('.', '~') }`} class="brk--image">
                                                                                <div class="brk--img">
                                                                                    <img src={tool.image} alt={tool.toolName}></img>

                                                                                </div>
                                                                            </a>
                                                                            <div class="brk--content">
                                                                                <div class="brk--meta">
                                                                                    <h5 class="brk--title mb-0">
                                                                                        <a target="_blank" href={`/detailsdata/${tool.toolName.replaceAll(' ', '_').replaceAll('.','~')}`} class="brk--name">
                                                                                            {tool.toolName} <a href={tool.link}><img width="15" height="15" src={WindowLink} alt="icon" className="img-responsive mx-2" /></a> </a>
                                                                                    </h5>
                                                                                    <div class="brk--meta-cell">
                                                                                        <div class="brk--date">
                                                                                            <a href="#"
                                                                                                class="freebtn">
                                                                                                <span>
                                                                                                    {tool.subscription_Type}</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="brk--excerpt">
                                                                                    <p
                                                                                        dangerouslySetInnerHTML={{ __html: this.getShortenedHTML(tool.long_Description) }}>
                                                                                        {/*{tool.long_Description.substring(0, 150)}...*/}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="brk--meta">
                                                                                    <div class="brk--meta-cell">
                                                                                        <a href="#"
                                                                                            class="category-btn ">
                                                                                            <span>{tool.category_Name}</span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="brk--meta-cell">
                                                                                        <div class="brk--date">
                                                                                            <li>
                                                                                                <a href="#" class=" brk-bg"
                                                                                                    data-modal="conversation">
                                                                                                    <i class="fas fa-comment commentIcon"></i>
                                                                                                </a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="brk--meta-cell">
                                                                                        <div class="brk--date">
                                                                                            <li>
                                                                                                <a href="#" class="rz--toggle-active"
                                                                                                    data-modal="conversation">
                                                                                                    <i
                                                                                                        class="fa fa-heart commentIcon"></i>
                                                                                                </a>
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </article>
                                                                    </a>
                                                                </div>

                                                                {index === 8 ?

                                                                      <a href="#" className="w-100" data-modal="signin">
                                                
                                                                        <img src='assetsfiles/wp-content/feature-imgs/button-top-ai-tools-banner.jpg' width="100%" className="py-4 py-lg-5" alt="banner image"></img> 
                                            </a>
                                                                    : ''}
                                                            </>

                                                        )}
                                                        
                                                    </div>
                                                    <div class="text-center">
                                                        <div class="brk-site-cta"><button class="btn btn-success" onClick={this.handleButtonClickNew}><span><i class="material-icon-HourglassEmpty"></i> &nbsp;View {3480-toolsdata.length} Tools</span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>
                ...</em></p>
            : this.rendertoolstable(this.state.toolsdata);
        return (
            <div>
                {contents}
            </div>

        );
    }
    async populateWeatherData() {
        const response = await fetch('/api/Tools/Gettools?catid=""');
        const data = await response.json();
        this.setState({ toolsdata: data.result, loading: false });
    }
}
export default DataFetch;

