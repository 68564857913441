import React, { Component } from 'react';
import WindowLink from './window-link.png';

export class Enc extends Component {
    static displayName = Enc.name;

    constructor(props) {
        super(props);
        this.state = { toolsdata: [], catname: '' };
    }
    componentDidMount() {
        this.populateWeatherData();
    }
    static rendertoolstable(toolsdata,catname) {
        return (

            <div className="mt-5 pt-5 mb-5 pb-5">
                <p>&nbsp;</p>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-4ea2ed1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="4ea2ed1" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1db6768"
                            data-id="1db6768" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-5850cc1 elementor-widget elementor-widget-brk-heading"
                                    data-id="5850cc1" data-element_type="widget" data-widget_type="brk-heading.default">
                                    <div class="elementor-widget-container">
                                        <div class="brk-section brk-elementor-row">
                                            <div class="brk--heading">
                                                <h2 class="brk--title brk--size-large">
                                                    <span>Explore {catname} tools</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-b3f39b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="b3f39b7" data-element_type="section">

                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-53932d1"
                            data-id="53932d1" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-b11b94a elementor-widget elementor-widget-brk-articles"
                                    data-id="b11b94a" data-element_type="widget" data-widget_type="brk-articles.default">
                                    <div class="elementor-widget-container">
                                        <div class="brk-listing-summary">
                                            <div class="brk--viewing">
                                                <p>
                                                    Robo found <strong className="text-success h4">{toolsdata.length}</strong> tools for you..
                                                </p>
                                            </div>
                                        </div>
                                        <section class="brk-section brk-elementor-row">
                                            <div class="brk-flex--full brk-justify-center">
                                                <div class="brk-articles" data-cols="3">
                                                    <div class="brk-grid brk-justify-center">
                                                        {toolsdata.map(tool =>
                                                            <div class="brk--cell" id={tool.tool_Id}>
                                                                <a target="_blank" href={`/detailsdata/${tool.toolName.replaceAll(' ', '_').replaceAll('.', '_') }`}>
                                                                    <article class="brk--item">

                                                                        <a target="_blank" href={`/detailsdata/${tool.toolName.replaceAll(' ', '_').replaceAll('.', '_') }`} class="brk--image">
                                                                            <div class="brk--img">
                                                                                <img src={tool.image}></img>

                                                                            </div>
                                                                        </a>
                                                                        <div class="brk--content">
                                                                            <div class="brk--meta">
                                                                                <h5 class="brk--title">
                                                                                    <a target="_blank" href={`/detailsdata/${tool.toolName.replaceAll(' ', '_').replaceAll('.', '_')}`} class="brk--name">
                                                                                        {tool.toolName} <img width="15" height="15" src={WindowLink} alt="icon" className="img-responsive mx-2" /></a>
                                                                                </h5>
                                                                                <div class="brk--meta-cell">
                                                                                    <div class="brk--date">
                                                                                        <a href="#"
                                                                                            class="freebtn">
                                                                                            <span>{tool.subscription_Type}</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="brk--excerpt">
                                                                                <p dangerouslySetInnerHTML={{ __html: tool.long_Description.substring(0, 200) }} ></p>
                                                                            </div>
                                                                            <div class="brk--meta">
                                                                                <div class="brk--meta-cell">
                                                                                    <a href="#"
                                                                                        class="brk--category brk-bg">
                                                                                        <span>{tool.category_Name}</span>
                                                                                    </a>
                                                                                </div>
                                                                                <div class="brk--meta-cell">
                                                                                    <div class="brk--date">
                                                                                        <li>
                                                                                            <a href="#" class="rz--toggle-active"
                                                                                                data-modal="conversation">
                                                                                                <i class="fas fa-comment commentIcon"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="brk--meta-cell">
                                                                                    <div class="brk--date">
                                                                                        <li>
                                                                                            <a href="#" class="rz--toggle-active"
                                                                                                data-modal="conversation">
                                                                                                <i
                                                                                                    class="fa fa-heart commentIcon"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </article>
                                                                </a>
                                                            </div>

                                                        )}


                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Enc.rendertoolstable(this.state.toolsdata,this.state.catname);
        return (
            <div>
                {contents}
            </div>

        );
    }
    async populateWeatherData() {
        const id = window.location.href.split('/')[4];

        fetch('/api/Tools/Gettools?catid=' + id, {
            method: "get",
            // data:  person,
        }).then((response) => {
            return response.json();
        })
            .then((jsonData) => {
                this.setState({ catname: jsonData.result[0].category_Name, toolsdata: jsonData.result, loading: false });
            });
    }
}
